import React, {useEffect} from 'react'
import {Button, Container} from "@mui/material";
import ScrollAnimation from 'react-animate-on-scroll'


import '../CSS/main.css'


import table1 from '../Images/russianpooltable.png'
import main_balls from '../Images/main-balls.jpeg'


export default function Main() {
    return (
        <section>
            <Container maxWidth={"lg"} sx={{paddingTop: 6, paddingBottom: 6}}>
                <ScrollAnimation animateOnce={true} animateIn='animate__animated animate__fadeIn animate__faster'>
                    <div className="main_container pt-2">

                        <div className="text">
                            <h1 className="text-5xl font-bold italic"><span className="text-yellow-400">Бильярдный клуб Royal</span> в
                                Мариуполе</h1>
                            <p className="text-2xl font-medium w-2/5 mt-4 mb-4">Бильярд – это не только спортивная игра,
                                но и прекрасный способ провести время в компании друзей.</p>
                            <p className="pc-text text-2xl font-medium w-2/5 mt-4 mb-4">Русский бильярд – один из самых
                                популярных видов бильярда в мире, который с каждым годом привлекает все больше
                                людей.</p>
                            <p className="pc-text text-2xl font-medium w-2/5 mt-4 mb-4">И если вы еще не знакомы с этой игрой,
                                то можете заглянуть к нам в бильярдный клуб, где сможете попробовать свои силы и
                                насладиться атмосферой игры.</p>

                            <div className="pool-table-mobile flex justify-start">
                                <img className="pool-table" width="90%" src={table1} alt="Бильярдный стол"/>
                            </div>
                            <a href="#form" className="button  mt-8 flex items-center justify-center"
                               data-wow-delay="1s">
                                <a href="#form"
                                   className="link text-2xl text-black font-medium"><span>Забронировать зал</span></a>
                            </a>
                        </div>

                        <div className="pool-table_container">
                            <img className="pool-table" width="100%" src={table1} alt="Бильярдный стол"/>
                            <div className="pool-table-bg"></div>
                        </div>
                    </div>
                </ScrollAnimation>
            </Container>

            {/*<img className="main_balls rounded-3xl" width={400} src={main_balls} alt="Бильярдные шары" />*/}
        </section>
    )
}
