import React from 'react'
import {Box, Container, Drawer, IconButton} from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from "react";

import '../CSS/navbar.css'

import logo from '../Images/logo.png'
import cross from '../Images/cross.png'


export default function NavBar() {

    const [open, setState] = useState(false);
    const toggleDrawer = (open) => (event) => {

        setState(open);
    };

    return (
        <nav id="navbar">
            <Container maxWidth={"lg"}>
                <div className="navbar_container flex items-center">
                    <div className="logo">
                        <a href="/">
                            <img src={logo} width={"250px"} alt="Логотип"/>
                        </a>
                    </div>

                    <div className="list">
                        <ul className="flex justify-between items-center">
                            <li className="elem"><a href="/#pluses" className="link font-normal">Наши преимущества</a>
                            </li>
                            <li className="elem"><a href="/#reviews" className="link font-normal">Отзывы</a></li>
                            <li className="elem"><a href="/#footer" className="link font-normal">Контакты</a></li>
                            <li>
                                <div className="text-left flex flex-col">
                                    <a href="tel:+79497036483" className="link font-normal text-white link">+7 (949)
                                        703-64-83 Владимир</a>
                                    <a href="tel:+79497294938" className="link font-normal text-white link">+7 (949)
                                        729-49-38 Татьяна</a>
                                    <p className="font-normal text-white">г. Мариуполь пр. Металлургов 133-А</p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="hamburger">
                        {/* hamburger icon shows the drawer on click */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)}
                            sx={{
                                display: {xs: 'block', md: 'none', color: "white"},
                                padding: 0,
                                marginLeft: "8px",
                                height: "90%"
                            }}>
                            <MenuIcon fontSize={"small"}/>
                        </IconButton>

                        {/* The outside of the drawer */}
                        <Drawer

                            anchor="right" //from which side the drawer slides in

                            variant="temporary" //if and how easily the drawer can be closed

                            open={open} //if open is true, drawer is shown

                            onClose={toggleDrawer(false)} //function that is called when the drawer should close
                        >

                            <Box style={{width: "100vw", textAlign: "center", background: "black", color: "white"}}>
                                <div style={{height: "100vh", position: "relative"}} className="drawer">
                                    <div className="drawer_container h-4/5 flex flex-col items-center justify-between">
                                        <img width={"90%"} height={"150px"} src={logo} className={"logo_drawer"}/>

                                        <ul className="list-drawer">
                                            <li className="list_element"><a onClick={toggleDrawer(false)}
                                                                            href="#pluses">Наши
                                                преимущества</a></li>
                                            <li className="list_element"><a onClick={toggleDrawer(false)}
                                                                            href="#form">Арендовать игровой зал</a></li>
                                            <li className="list_element"><a onClick={toggleDrawer(false)}
                                                                            href="#reviews">Отзывы</a></li>
                                            <li className="list_element"><a onClick={toggleDrawer(false)}
                                                                            href="#footer">Контакты</a></li>
                                        </ul>

                                        <div className="button" href="#footer">
                                            <a href="https://yandex.ru/maps/10366/mariupol/?from=mapframe&ll=37.551820%2C47.099094&mode=usermaps&source=mapframe&um=constructor%3A56a73a374aec08d02fd87158a17b0832884d4956ef0ecbcdf9a9e61feeb70dc0&utm_source=mapframe&z=15"
                                               className="navbar_contact">
                                                <span className="text">г. Мариуполь пр. Металлургов 133-А</span>
                                            </a>

                                            <a className="navbar_contact" href={"tel:+79497036483"}>
                                            <span className="text">+7 (949)
                                        703-64-83 <span className="text text-yellow-400">Владимир</span></span>
                                            </a>
                                            <a className="navbar_contact" href={"tel:+79497294938"}>
                                            <span className="text">+7 (949)
                                            729-49-38 <span className="text text-yellow-400">Татьяна</span></span>
                                            </a>
                                        </div>

                                        <img src={cross} width={32} onClick={toggleDrawer(false)}
                                             style={{position: "absolute", top: "5px", right: "5px"}}/>

                                    </div>
                                </div>

                            </Box>
                        </Drawer>
                    </div>
                </div>
            </Container>

        </nav>
    )
}
