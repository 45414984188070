import React from 'react'
import {useRef} from 'react';

import {Box, Container, Modal} from "@mui/material";

import '../CSS/pluses.css'

import reward from '../Images/reward.png';
import work_time from '../Images/worktime.png'
import like from '../Images/like.png';
import map from '../Images/map.png';

import table1 from '../Images/table1.png';
import ball from '../Images/ball.png'
import ball2 from '../Images/ball2.png'
import ball3 from '../Images/zamena3and7.png'
import ball4 from '../Images/ball4.png'
import ScrollAnimation from "react-animate-on-scroll";

import menu1 from '../Images/menu1.png'
import menu2 from '../Images/menu2.jpg'

import ModalImage from 'react-modal-image'
import ImageZooms from "react-image-zooms";
import Typography from "@mui/material/Typography";


export default function Pluses() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const windowWidth = useRef(window.innerWidth);

    const style = {
        position: 'absolute',
        top: '5%',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        bgcolor: 'background.paper',
        width: "80%",
        minWidth:"350px",
        maxWidth: "600px",
        boxShadow: 24,
        p: 4,
    };

    return (
        <section id="pluses" className={"plusses_section"}>
            <ScrollAnimation animateOnce={true} animateIn='animate__animated animate__fadeIn animate__faster'>
                <Container maxWidth={"lg"} sx={{paddingTop: 2, paddingBottom: 2}}>
                    <div className="pluses_container flex flex-wrap flex-col relative">
                        <h2 className="text-5xl text-white text-center"><span
                            className="text-yellow-400">Наши</span> преимущества</h2>
                        <div className=" w-full mt-4">
                            <ul className="pluses_list flex text-white text-center">
                                <li className="plus_elem">
                                    <div className="plus flex flex-col items-center w-full">
                                        <img className={"plus_img"} width={128} src={reward} alt="Плюс1"/>
                                        <p className="description w-full">Удобное расположение
                                            бильярдных столов.
                                            Вежливый персонал.</p>
                                    </div>
                                </li>
                                <li className="plus_elem">
                                    <div className="plus flex flex-col items-center w-full">
                                        <img className={"plus_img"} width={128} src={work_time} alt="Плюс2"/>
                                        <div className="description w-full">
                                            <p>Вт - Чт - 12:00 - 23:00</p>
                                            <p>Пт - Сб<br/> (до последнего клиента)</p>
                                            <p>Понедельник - выходной</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="plus_elem">
                                    <div className="plus flex flex-col items-center w-full">
                                        <img className={"plus_img"} width={128} src={like} alt="Плюс3"/>
                                        <p className="description">В нашем заведении бильярдные
                                            столы выставлены с точностью до
                                            0,04 мм на метр.
                                            Новое сукно, Бельгийские шары
                                            именитой фирмы "Aramith"</p>
                                    </div>
                                </li>
                                <li className="plus_elem">
                                    <div className="plus flex flex-col items-center w-full">
                                        <img className={"plus_img"} width={128} src={map} alt="Плюс4"/>
                                        <p className="description">Мы находимся в г. Мариуполь,
                                            по адресу пр. Металлургов 133-А</p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className=" mt-8 flex justify-around menu">
                            <div className="image-box firstMenu">
                                <img src={menu1} onClick={handleOpen} alt={"Меню1"}/>;
                            </div>
                            <div className="image-box secondMenu">
                                <img src={menu2} onClick={handleOpen2} alt={"Меню2"}/>;
                            </div>
                        </div>


                        <a href="#form" className="flex self-center mt-8">
                            <ScrollAnimation animateOnce={true} animateIn='animate__animated animate__fadeInUp'>
                                <div className="button flex items-center justify-center">
                                    <span className="link text-2xl text-black font-medium">Забронировать
                                        зал</span>
                                </div>
                            </ScrollAnimation>
                        </a>

                    </div>


                </Container>

            </ScrollAnimation>
            <img className="pool-table-bg" width={400} src={table1} alt="бильярдный стол"/>

            <img className="shadow-ball-1" width={250} src={ball3} alt="бильярдный шар"/>

            <img className="shadow-ball-2" width={"100%"} src={ball3} alt="бильярдный шар"/>

            <Modal
                open={open}
                onClose={handleClose}
                disableAutoFocus={true}
                disableEnforceFocus={true}
            >
                <div style={style} className="menu-image-box w-1/2 h-4/5 flex justify-center content-center">
                    <div className="h-full ">
                        <img src={menu1} alt={"Меню1"} className={'h-full w-full'} onClick={handleClose}/>
                    </div>
                </div>
            </Modal>

            <Modal
                open={open2}
                onClose={handleClose2}
                disableAutoFocus={true}
                disableEnforceFocus={true}
            >
                <div style={style} className="menu-image-box w-1/2 h-4/5 flex justify-center content-center">
                    <div className="h-full ">
                        <img src={menu2} alt={"Меню2"} className={'h-full w-full'} onClick={handleClose2}/>
                    </div>
                </div>
            </Modal>
        </section>
    )
}
