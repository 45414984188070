import React from 'react'
import {Box, Container, Modal, Typography} from "@mui/material";
import InputMask from 'react-input-mask';

import '../CSS/form.css'
import {useState} from "react";

import form_image from '../Images/form-image.jpeg'
import ScrollAnimation from "react-animate-on-scroll";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Form() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    async function wait() {
        // Создаем промис, который разрешится через 5 секунд
        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

        // Ждем 5 секунд
        await delay(5000);

        setOpen(false)
    }

    function submitForm() {
        setOpen(true)
        wait()


        // Get form data
        const name = document.getElementById("name").value;
        const phone = document.getElementById('phone').value.toString();
        const idea = document.getElementById('idea').value;


        // Get CSRF token
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        // Send data to PHP script using AJAX
        fetch('https://royalclubmrpl.ru/api/greeting', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken // Include CSRF token in headers
            },
            body: JSON.stringify({name, phone, idea, _token: csrfToken})
        })
            .then(response => response.text())
            .then(data => {

            })
            .catch(error => {
                console.error('Error:', error);
            });
    }


    return (
        <section id="form">
            <Container maxWidth={"lg"}>
                <ScrollAnimation initiallyVisible={true} animateOnce={true}
                                 animateIn='animate__animated animate__pulse animate__slower'>
                    <div className="form_container flex justify-between items-center">

                        <div className="elem_container flex justify-between items-center">
                            <div
                                className="feedback-form_container  p-8 flex flex-col items-center rounded-3xl w-2/5 h-2/3">

                                <h2 className="form_title text-3xl text-center text-white">Арендовать <span
                                    className="text-yellow-400">игровой зал</span></h2>
                                <div className="feedback-form mt-8 mb-8 w-full">
                                    <div className="feedback_inputs flex flex-col items-center w-full">
                                        <input placeholder="Имя"
                                               className="feedback_input w-full input text-lg h-12 pl-4 pt-3 pb-2"
                                               name="name" id="name"/>
                                        <InputMask mask="+7\(999) 99 999 99" maskChar={null}
                                                   className="feedback_input input w-full h-12 text-lg pl-4 pt-3 pb-2"
                                                   name="phone" id="phone" placeholder={"Телефон"}/>
                                        <textarea placeholder="Расскажите о ваших планах"
                                                  className="feedback_large-input input resize-none w-full h-36 text-lg pl-4 pt-3 pb-2"
                                                  name="idea"
                                                  id="idea"/>
                                    </div>
                                    <div className="button-container flex self-center justify-center mt-12">

                                        <a className="button link text-2xl text-black font-medium flex items-center justify-center cursor-pointer"
                                           onClick={submitForm}><span>Отправить</span></a>
                                    </div>
                                </div>

                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Мы получили вашу заявку! 🎱
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                                            Спасибо за доверие. Мы свяжемся с вами в ближайшее время для уточнения
                                            деталей.
                                        </Typography>
                                    </Box>
                                </Modal>
                            </div>


                            <div className="image w-2/4">
                                <img className="rounded-2xl" src={form_image} alt="Партия в бильярд" width={"100%"}/>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </Container>

        </section>
    )
}
