import React from 'react'
import {Container} from "@mui/material";

import '../CSS/policy.css'


export default function Policy() {
    return (
        <div>
            <Container maxWidth={"lg"} sx={{paddingTop:6, paddingBottom: 6}}>
                <div className="privacy_container text-white">
                    <h2 className={"text-3xl font-bold mb-12"}>Политика конфиденциальности</h2>
                    <div className="privacy_elem">
                        <p className="text-2xl font-semibold">1. Сбор информации</p>
                        <p>Мы фиксируем и сохраняем в базе данных сайта вашу персональную информацию когда вы заполняете
                            контактную информацию при оформлении заказа на сайте. Информация включает ваше имя, фамилию,
                            отчество, номер телефона, email и адрес. Кроме того, мы автоматически регистрируем ваш
                            компьютер и браузер, включая IP, ПО и аппаратные данные, а также адрес запрашиваемой
                            страницы.</p>
                    </div>

                    <div className="privacy_elem">
                        <p className="text-2xl font-semibold">2. Использование информации</p>
                        <p>Информация, которую мы получаем от вас, может быть использована, чтобы:
                            <br/>- Сделать услуги соответствующими вашим индивидуальным запросам
                            <br/>- Предложить персонализированную рекламу
                            <br/>- Улучшить наш сайт
                            <br/>- Улучшить систему поддержки пользователей
                            <br/>- Связаться с вами по электронной почте
                            <br/>- Устроить акцию, конкурс или организовать исследование</p>
                    </div>

                    <div className="privacy_elem">
                        <p className="text-2xl font-semibold">3. Защита личных данных при онлайн-продажах</p>
                        <p>Мы являемся единственным владельцем информации, собранной на данном сайте. Ваши личные данные
                            не будут проданы или каким-либо образом переданы третьим лицам по каким-либо причинам, за
                            исключением необходимых данных для выполнения запроса или транзакции, например, при отправке
                            заказа.</p>
                    </div>

                    <div className="privacy_elem">
                        <p className="text-2xl font-semibold">4. Раскрытие информации третьим лицам</p>
                        <p>Мы не продаем, не обмениваем и не передаем личные данные сторонним компаниям. Это не
                            относится к надежным компаниям, которые помогают нам в работе сайта и ведении бизнеса при
                            условии, что они соглашаются сохранять конфиденциальность информации.
                            Мы готовы делиться информацией, чтобы предотвратить преступления или помочь в их
                            расследовании, если речь идет о подозрении на мошенничество, действиях, физически угрожающих
                            безопасности людей, нарушениях правил использования или в случаях, когда это предусмотрено
                            законом.
                            Неконфиденциальная информация может быть предоставлена другим компаниям в целях маркетинга,
                            рекламы и т.д.</p>
                    </div>

                    <div className="privacy_elem">
                        <p className="text-2xl font-semibold">5. Защита информации</p>
                        <p>Мы используем различные средства безопасности, чтобы гарантировать сохранность ваших личных
                            данных. К вашим услугам самое современное шифрование. Только те сотрудники, которые работают
                            с конкретным заданием (например, техническая поддержка или проведение оплаты) получают
                            доступ к личным данным. Сервера и компьютеры, на которых записана конфиденциальная
                            информация, находятся в безопасном окружении.</p>
                    </div>

                    <div className="privacy_elem">
                        <p className="text-2xl font-semibold">6. Использование файлов «cookie»</p>
                        <p>Наши файлы «cookie» используются для улучшения доступа к сайту и определения повторных
                            посещений. Кроме того, они позволяют отследить наиболее интересующие запросы. Файлы «cookie»
                            не передают никакую конфиденциальную информацию.</p>
                    </div>

                    <div className="privacy_elem">
                        <p className="text-2xl font-semibold">7. Отказ от подписки</p>
                        <p>Мы используем электронную почту, чтобы предоставить вам информацию по вашему заказу, новостям
                            компании, информации по продуктам и т.д. Если вы желаете отказаться от подписки, в каждом
                            письме даны подробные инструкции, как вы можете это сделать.</p>
                    </div>

                    <div className="privacy_elem">
                        <p className="text-2xl font-semibold">8. Согласие</p>
                        <p>Пользуясь услугами нашего сайта, вы автоматически соглашаетесь с нашей политикой конфиденциальности.</p>
                    </div>
                </div>
            </Container>
        </div>
    )
}
