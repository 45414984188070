import React from 'react'
import Main from "./Main";
import Pluses from "./Pluses";
import Form from "./Form";
import Review from "./Review";

export default function MainPage() {
    return (
        <>
            <Main></Main>
            <Pluses></Pluses>
            <Form></Form>
            <Review></Review>
        </>
    )
}
