import React from 'react'
import {Container} from "@mui/material";

import '../CSS/footer.css'

import logo from '../Images/logo.png';
import telegram from '../Images/telegram.svg'
import whatsapp from '../Images/whatsapp.svg'
import linkedin from '../Images/linkedin.png';

import webcommand_logo from '../Images/Logo Green.svg'

import map_ico from '../Images/map-ico.png'
import phone from '../Images/phone.png'
import mail from '../Images/mail.png'
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <footer id="footer">
            <Container maxWidth="lg" sx={{paddingTop: 2, paddingBottom: 2}}>
                <div className="footer_container text-white flex justify-between items-start h-full">
                    <div className="info h-3/4 flex flex-col justify-between">
                        <div>
                            <img src={logo} width={175} alt="Логотип"/>
                        </div>

                        <div className="webcommand">
                            <p className="webcommand_description">Разработано</p>
                            <a href="https://web-command.ru/">
                                <img width={"50%"} src={webcommand_logo}/>
                            </a>
                        </div>
                    </div>

                    <div className="social-media flex h-full items-start justify-center">
                        <div className="flex flex-col items-center">
                            <div>
                                <p>Социальные сети</p>

                                <div>
                                    <ul className="flex justify-center">
                                        <li className="social-media-elem"><a href="https://t.me/royalklub2023"><img width={32} src={telegram}
                                                                                           alt="Telegram"/></a></li>
                                        <li className="social-media-elem"><a href="https://api.whatsapp.com/send/?phone=%2B79497036483&text&type=phone_number&app_absent=0"><img width={32} src={whatsapp}
                                                                                           alt="WhatsApp"/></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="flex flex-col items-center justify-start h-full">
                        <div className="contacts h-full flex flex-col justify-between items-start">
                            <div>
                                <div className="flex items-center">
                                    <img className="mr-2" width={16} src={map_ico} alt="иконка карты"/>
                                    <p>г. Мариуполь пр. Металлургов 133-А</p>
                                </div>

                                <div className="flex items-center">
                                    <img className="mr-2" width={16} src={phone} alt="иконка карты"/>
                                    <p>+7 (949) 703-64-83 Владимир</p>
                                </div>

                                <div className="flex items-center">
                                    <img className="mr-2" width={16} src={phone} alt="иконка карты"/>
                                    <p>+7 (949) 729-49-38 Татьяна</p>
                                </div>

                                <div className="flex items-center">
                                    <img className="mr-2" width={16} src={mail} alt="иконка карты"/>
                                    <p>vova.ovseenko26.06.2015@gmail.com</p>
                                </div>
                            </div>


                            <div>
                                <p>ИП Овсеенко В.Н.</p>
                                <p>ИНН 614083507497</p>
                                <p>ОГРНИП 323930100759961</p>
                            </div>

                            <p className="text-center">
                                <Link to={"/policy"}>Политика конденциальности</Link>
                            </p>
                        </div>
                    </div>
                    <div className="webcommand_down">
                        <p className="webcommand_description">Разработано</p>
                        <a href="https://web-command.ru/">
                            <img width={"100%"} src={webcommand_logo}/>
                        </a>
                    </div>
                </div>
            </Container>
        </footer>
    )
}
