import './index.css'

import 'animate.css';

import NavBar from "./Components/NavBar";
import Main from "./Components/Main";
import Pluses from "./Components/Pluses";
import Form from "./Components/Form";
import Review from "./Components/Review";
import Footer from "./Components/Footer";
import {CursorifyProvider, DefaultCursor} from "@cursorify/react";
import {EmojiCursor} from "@cursorify/react/dist/cursors";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Policy from "./Components/Policy";
import MainPage from "./Components/MainPage";


function App() {
    return (
        <BrowserRouter>
            <>
                <head>
                    <script src="https://cdn.tailwindcss.com"></script>
                    <title>Бильярд в Мариуполе</title>

                    <meta name="csrf-token" content="{{ csrf_token() }}"/>

                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                    />
                </head>
                <div className="App bg-black">
                    <NavBar></NavBar>
                    <main>
                        <Routes>
                            <Route path={"/"} element={<MainPage/>}></Route>
                            <Route path={"/policy"} element={<Policy/>}></Route>
                        </Routes>
                    </main>
                    <Footer></Footer>
                </div>
            </>

        </BrowserRouter>
    );
}

export default App;
