import React from 'react'
import {Container, Rating, TextField} from "@mui/material";

import '../CSS/review.css'

import Carousel from 'react-material-ui-carousel'
import Item from "./Item";
import {useEffect, useState} from 'react';

import star from '../Images/star.png'
import ball from '../Images/ball.png'
import ball2 from '../Images/ball2.png'
import ball3 from '../Images/ball3.png'
import ball4 from '../Images/ball4.png'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputMask from "react-input-mask";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Review() {

    const [reviewList, setReviewList] = useState([])

    const [slides, setSlides] = useState([])

    // const reviewList = [
    //     {
    //         name: "Превосходная качественная доска! 🎱",
    //         rating: 5,
    //         text: "Эта доска превзошла мои ожидания. Отличное качество материалов и прочная конструкция делают игру в бильярд еще более увлекательной. Рекомендую!"
    //     },
    //     {
    //         name: "Отличное соотношение цены и качества! 👍",
    //         rating: 4,
    //         text: "Приобрел этот товар несколько недель назад, и не могу нарадоваться! Легко собрать, удобно использовать и отлично выглядит в комнате. Покупкой доволен."
    //     },
    //     {
    //         name: "Идеальное дополнение к домашнему бару! 🍻",
    //         rating: 5,
    //         text: "Этот бильярдный стол стал настоящим украшением нашего домашнего бара. Качество сборки на высшем уровне, а игровой процесс приносит массу удовольствия. Все друзья в восторге!"
    //     },
    //     {
    //         name: "Очень доволен! ⭐️",
    //         rating: 5,
    //         text: "Покупка превзошла все мои ожидания. Быстрая доставка, качественный товар и прекрасное обслуживание. Спасибо за отличный опыт покупки!"
    //     },
    //     {
    //         name: "Лучшее вложение в развлечения для дома! 🏡",
    //         rating: 4,
    //         text: "Игра в бильярд стала нашим новым семейным хобби благодаря этому товару. Прекрасное качество и удобство использования делают его идеальным выбором для домашнего развлечения. Всем советую!"
    //     }
    // ];

    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [rating, setRating] = useState(4);

    const handleThankYouOpen = () => setOpen(true);
    const handleThankYouClose = () => setOpen(false);

    const [ThankYouOpen, ThankYouSetOpen] = useState(false);
    const [showThankYouModal, setShowThankYouModal] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        getAllReviews()
    }, []);

    function positionSort(reviewList) {
        return reviewList.sort(function (a, b) {
            if (a.position < b.position) {
                return -1;
            } else if (a.position > b.position) {
                return 1;
            } else {
                return 0;
            }
        })
    }

    function slideReviews() {
        let slidesTemp = [];

        for (let i = 0; i < reviewList.length; i += 3) {
            slidesTemp.push(reviewList.slice(i, i + 3));
        }

        setSlides(slidesTemp)
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function getAllReviews() {
        await sleep(1000);

        // Get CSRF token
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        let repsonce = axios.get("https://royalclubmrpl.ru/api/rating/getAllReview", {

            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken // Include CSRF token in headers
            },
            data: {
                _token: csrfToken
            }

        })
            .then((resp) => {

                let reviewList = resp.data; // Ваш массив с данными отзывов

                console.log(reviewList)

                let filteredReviews = reviewList.filter(review => review.is_show);

                console.log(filteredReviews)

                // Сортировка по позиции
                reviewList = positionSort(filteredReviews)
                setReviewList(reviewList)


                let slidesTemp = [];

                for (let i = 0; i < reviewList.length; i += 3) {
                    slidesTemp.push(reviewList.slice(i, i + 3));
                }

                setSlides(slidesTemp)
            })
            .catch((error) => {
                console.error('Ошибка при получении данных:', error);
            });

    }

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(name)
        console.log(phone)
        console.log(message)
        console.log(rating)

        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        axios.post('https://royalclubmrpl.ru/api/rating', {
            name,
            phone,
            message,
            rating,
            _token: csrfToken
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken // Include CSRF token in headers
            }
        })
            .then(response => {
                console.log('Response:', response.data);
                // Обработка данных, если нужно
            })
            .catch(error => {
                console.error('Error:', error);
            });


        setShowThankYouModal(true)
        setTimeout(() => {
            setShowThankYouModal(false);
        }, 5000);

        setOpen(false);
    };


    useEffect(() => {
        slideReviews();
    }, []);


    return (
        <section id="reviews" className="review_section">
            <Container maxWidth={"lg"}>
                <div className="review_container flex flex-col relative">
                    <h2 className="reviews_title text-5xl text-white text-center">Отзывы</h2>


                    <div className="mt-12 mb-12 reviews_carousels">
                        <Carousel
                            className="reviews_carousel"
                            indicatorIconButtonProps={{
                                style: {
                                    color: "#C4C4C4",

                                    marginTop: "10px",
                                    padding: "2px"
                                }
                            }}
                            activeIndicatorIconButtonProps={{
                                style: {
                                    backgroundColor: "#C4C4C466",
                                }
                            }}
                            indicatorContainerProps={{
                                style: {
                                    marginTop: '25px'
                                }
                            }}
                            navButtonsAlwaysInvisible={true}
                            animation="fade"
                            duration={1000}
                        >
                            {slides.map((slide, index) => (
                                <div key={index}
                                     style={{display: 'flex', justifyContent: "space-around", height: "300px"}}>
                                    {slide.map((item, i) => (
                                        <Item key={i} name={item.client_name} rating={parseInt(item.rating)}
                                              text={item.comment}/>
                                    ))}
                                </div>
                            ))}
                        </Carousel>


                        <Carousel
                            className="reviews_carousel_min"
                            indicatorIconButtonProps={{
                                style: {
                                    color: "#C4C4C4",

                                    marginTop: "10px",
                                    padding: "2px"
                                }
                            }}
                            activeIndicatorIconButtonProps={{
                                style: {
                                    backgroundColor: "#C4C4C466",
                                }
                            }}
                            indicatorContainerProps={{
                                style: {
                                    marginTop: '25px'
                                }
                            }}
                            navButtonsAlwaysInvisible={true}
                            animation="fade"
                            duration={1000}
                        >
                            {reviewList.map((slide, index) => (
                                <div key={index}
                                     style={{display: 'flex', justifyContent: "space-around", height: "300px"}}>
                                    <Item key={index} name={slide.client_name} rating={parseInt(slide.rating)}
                                          text={slide.comment}/>
                                </div>
                            ))}
                        </Carousel>
                    </div>

                    <div className="self-center">
                        <ScrollAnimation animateOnce={true} animateIn='animate__animated animate__fadeInUp'>
                            <button className="button text-2xl" onClick={handleOpen}><span>Оставить отзыв</span>
                            </button>
                        </ScrollAnimation>
                    </div>


                    <Modal className="modal-review" open={open} onClose={handleClose}
                           aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <Box sx={style}>
                            <h2 className="text-xl font-bold mb-4">Оставить отзыв</h2>
                            <form className="flex flex-col">
                                <div className="mb-4">
                                    <label htmlFor="name"
                                           className="block text-gray-700 text-sm font-bold mb-2">Имя</label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">Телефон</label>
                                    <InputMask variant="outlined" mask="+7\(999) 99 999 99" alwaysShowMask={true}
                                               maskChar={null}
                                               className="feedback_input input h-12 text-lg h-12 pl-4 pt-3 pb-2 w-full border-2"
                                               name="phone" id="phone" onChange={(e) => setPhone(e.target.value)}/>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="review"
                                           className="block text-gray-700 text-sm font-bold mb-2">Отзыв</label>
                                    <textarea
                                        id="review"

                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        className="review shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        rows={4}
                                    />
                                </div>
                                <div className="mb-4">
                                    <Rating
                                        name="simple-controlled"
                                        value={rating}
                                        onChange={(event, newValue) => setRating(newValue)}
                                    />
                                </div>
                                <button className="button" onClick={handleSubmit}>
                                    <span>Отправить</span>
                                </button>
                            </form>
                        </Box>
                    </Modal>

                    <Modal open={showThankYouModal}>
                        <Box sx={style}
                             className="w-full max-w-xs m-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                            <h2 className="text-xl font-bold mb-4">Спасибо за ваш отзыв!</h2>
                            <p className="text-gray-700">Ваш отзыв отправлен успешно. Мы очень ценим ваше мнение.</p>
                        </Box>
                    </Modal>
                </div>
            </Container>
        </section>
    )
}
